import React from 'react';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import PostHero from '../components/blog/PostHero';
import Breadcrumbs from '../components/blog/Breadcrumbs';
import styled from 'styled-components';
import { Container } from '../styles/layout';
import SliceBody from '../components/SliceBody';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import routes from '../routes';
import pluralize from 'pluralize';


const BreadcrumbsContainer = styled(Container)`
  margin-top: 60px;
  > * {
    grid-column: 2 / span 8;
  }
`;

class BlogPost extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("Post", {
      title: `${this.props.data.prismicBlogPost.data.title} | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const data = props.data;
    const postData = props.data.prismicBlogPost.data;
    const postDataRaw = JSON.parse(props.data.prismicBlogPost.dataString);

    return (
      <Layout reverseLogo={true}>
        <Helmet>
          {titleTags(`${postData.title} | ${data.prismicSite.data.name}`)}
          {descriptionTags(postData.description)}
          {imageTags(postData.image.url)}
        </Helmet>
        <PostHero
          category={postData.category}
          image={postData.image}
          author={postData.author}
          title={postData.title}
          date={postData.date}
          showShareIcons
        />
        <BreadcrumbsContainer>
          <Breadcrumbs
            topLink={{to: routes.journal, label: 'All Posts'}}
            middleLink={{to: `${routes.journal}?category=${postData.category}`, label: pluralize(postData.category)}}
            childLink={{label: postData.title}}
          />
        </BreadcrumbsContainer>
        <SliceBody
          postData={postDataRaw}
        />
      </Layout>
    );
  }
}

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    prismicSite {
      data {
        name
      }
    }
    prismicBlogPost(uid: {eq: $slug}) {
      uid
      dataString
      data {
        title
        date
        author
        description
        category
        image {
          alt
          copyright
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;